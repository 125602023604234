import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AudioPlayer from '@liripeng/vue-audio-player/packages'
//使用bootstrap框架，安装：npm install bootstrap，官网：https://getbootstrap.com/ https://www.bootcss.com/
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
//使用bootstrap图标，安装：npm i bootstrap-icons，官网：https://icons.getbootstrap.com
import 'bootstrap-icons/font/bootstrap-icons.css'
//使用弹出插件，安装：npm i layer-src，官网：http://layui-doc.pearadmin.com/layer/mobile/
import 'layer-src/dist/mobile/layer';
import "layer-src/dist/mobile/need/layer.css";

NodePlayer.load(() => {
    let app = createApp(App);
    //注册全局获取焦点指令
    app.directive('focus',{
        mounted(elem) {
            elem.focus();
        },
    })
    app.use(store).use(router).use(AudioPlayer).mount('#app');
})