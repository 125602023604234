<style scoped>
    .pearl-tool{
        height: 12%;
        background: #000;
        padding: .1% 1% 0 1%;
        overflow: hidden;
    }
    .pearl-tool>div{
        float: left;
        font-size: .5em;
    }
    .pearl-title{
        background: linear-gradient(0deg,#000 50%,#606060 100%);
        color: #cfcfa9;
        height: 100%;
        border-top-left-radius: .4em;
        border-top-right-radius: .4em;
        padding: 0 1.2%;
    }
    .pearl-compute{
        background: linear-gradient(0deg,#ac7935 0%,#fefac4 70%,#e1aa51 100%);
        border-radius: .2em;
        margin: .2% .5%;
        padding: .2% .3%;
        line-height: 100%;
        cursor: pointer;
    }
    .pearl-compute.red{
        color: #fd1b11;
    }
    .pearl-compute.blue{
        color: #0000cc;
    }
    .pearl-suppose{
        color: #cfcfa5;
        margin-left: .8%;
        width: 10%;
    }
    .pearl-suppose>label{
        margin-left: 5%;
        vertical-align: middle;
    }
    .pearl-suppose>label>span{
        border: .2em solid;
        display: inline-block;
    }
    .pearl-suppose>label>span:nth-child(odd){
        border-radius: 50%;
        width: 1em;
        height: 1em;
    }
    .pearl-suppose>label>span:nth-child(even){
        height: .95em;
        transform: rotate(30deg);
        margin: 0 4%;
        border-top: 0;
        border-bottom: 0;
        border-left: 0;
    }
    .pearl-info{
        color: #cfcfa5;
        margin-right: 2%;
    }
    .pearl-container{
        height: 88%;
        background: #fff;
    }
    .pearl-container>canvas{
        float: left;
    }
    .pearl-one{
        width: 37%;
        height: 100%;
    }
    .pearl-two{
        width: 63%;
        height: 50%;
    }
    .pearl-three,.pearl-four,.pearl-five,.pearl-six{
        width: 31.5%;
        height: 25%;
    }
</style>
<template>
    <div>
        <div class="pearl-tool clearfix">
            <div class="pearl-title">珠路</div>
            <div class="pearl-compute red" @click="showCalculate(game==1?'A':'M')">{{game==1?"庄":"龙"}}问路</div>
            <div class="pearl-compute blue" @click="showCalculate(game==1?'B':'N')">{{game==1?"闲":"虎"}}问路</div>
            <div class="pearl-suppose d-flex align-items-center">
                <span class="text-nowrap">下局开{{game==1?"庄":"龙"}}</span>
                <label class="d-flex">
                    <span :style="nextResCol.hollow_z"></span>
                    <span class="mx-1" :style="nextResCol.diagonal_z"></span>
                    <span :style="nextResCol.solid_z"></span>
                </label>
            </div>
            <div class="pearl-suppose d-flex align-items-center">
                <span class="text-nowrap">下局开{{game==1?"闲":"虎"}}</span>
                <label class="d-flex">
                    <span :style="nextResCol.hollow_x"></span>
                    <span class="mx-1" :style="nextResCol.diagonal_x"></span>
                    <span :style="nextResCol.solid_x"></span>
                </label>
            </div>
            <div class="pearl-info">{{game==1?"庄":"龙"}}：{{pearlInfo.z}}</div>
            <div class="pearl-info">{{game==1?"闲":"虎"}}：{{pearlInfo.x}}</div>
            <div class="pearl-info">和：{{pearlInfo.h}}</div>
            <div class="pearl-info" v-show="game==1">庄对：{{pearlInfo.zd}}</div>
            <div class="pearl-info" v-show="game==1">闲对：{{pearlInfo.xd}}</div>
        </div>
        <div class="pearl-container clearfix">
            <canvas class="pearl-one" :width="canvasWidth*.37" :height="canvasHeight"></canvas>
            <canvas class="pearl-two" :width="canvasWidth*.63" :height="canvasHeight*.5"></canvas>
            <canvas class="pearl-three" :width="canvasWidth*.315" :height="canvasHeight*.25"></canvas>
            <canvas class="pearl-four" :width="canvasWidth*.315" :height="canvasHeight*.25"></canvas>
            <canvas class="pearl-five" :width="canvasWidth*.315" :height="canvasHeight*.25"></canvas>
            <canvas class="pearl-six" :width="canvasWidth*.315" :height="canvasHeight*.25"></canvas>
        </div>
    </div>
</template>
<script>
import { drawCanvas,fillRoomPearl,delPearlTie } from '@/assets/js/common.js';
    export default{
        props: ["tableId","game"],
        data() {
            return {
                canvasWidth: 0,//路珠区总宽度
                canvasHeight:0,//路珠区总高度
                pearlInfo:{
                    z:0,
                    x:0,
                    h:0,
                    zd:0,
                    xd:0
                },//路珠统计
                nextResCol:{
                    hollow_z:"",//空心(庄)
                    diagonal_z:"",//斜线(庄)
                    solid_z:"",//实心(庄)
                    hollow_x:"",//空心(闲)
                    diagonal_x:"",//斜线(闲)
                    solid_x:"",//实心(闲)
                },//下局开庄闲的颜色
                pearlArr:[],//当前台面路珠数据
                isFlicker:false,//问路是否在闪烁
            }
        },
        mounted(){
            this.canvasWidth=document.querySelector(".pearl-container").clientWidth;
            this.canvasHeight=document.querySelector(".pearl-container").clientHeight;
            this.$root.addMessage("pearlHistory",(data) => {
                switch (data.command) {
                    case "all_getroompearl"://获取台面路珠
                        if(data.useto=="table"&&data.id==this.tableId){
                            this.pearlArr=data.pearl;
                            drawCanvas(document.querySelectorAll(".pearl-one"),15,6);
                            drawCanvas(document.querySelectorAll(".pearl-two"),52,6);
                            drawCanvas(document.querySelectorAll(".pearl-three,.pearl-four,.pearl-five,.pearl-six"),26,3);
                            var count = data.count.reduce(function (pre,val) {
                                return pre + val;
                            })
                            var arr=JSON.parse(JSON.stringify(data.pearl));
                            this.drawTextBigPearl([].concat.apply([], arr).slice(-(84 + count % 6)));
                            arr=JSON.parse(JSON.stringify(data.pearl));
                            fillRoomPearl(document.querySelector(".pearl-two"),6,52,delPearlTie(arr.reverse(),52).reverse());
                            this.drawOrderBigPearl(data.pearl);
                            arr=JSON.parse(JSON.stringify(data.pearl));
                            var array=delPearlTie(arr.reverse(),52).reverse();
                            this.drawSmallPearl(document.querySelector(".pearl-three"),array,0);
                            this.drawSmallPearl(document.querySelector(".pearl-five"), array, 1);
                            this.drawSmallPearl(document.querySelector(".pearl-four"), array, 2);
                        }
                        break;
                    case "bac_gameresult"://接收游戏结果
                        if(data.id==this.tableId){
                            this.isFlicker=false;
                            this.$root.ws.wsSend('all_getroompearl',1, this.tableId, 52,"table", this.$root.user.name, this.$root.user.guid);
                            // var arrZ = ['D', 'M', 'F', 'E', 'A'];
                            // var arrX = ['H', 'N', 'G', 'I', 'B'];
                            // var arrH = ['L', 'J', 'K', 'O', 'C'];
                            // if (this.pearlArr && this.pearlArr.length > 0 && (
                            //     (arrZ.indexOf(data.pearl.substr(0, 1)) != -1 && arrZ.indexOf(this.pearlArr[this.pearlArr.length - 1][0].substr(0, 1)) != -1) ||
                            //     (arrX.indexOf(data.pearl.substr(0, 1)) != -1 && arrX.indexOf(this.pearlArr[this.pearlArr.length - 1][0].substr(0, 1)) != -1) ||
                            //     (arrH.indexOf(data.pearl.substr(0, 1)) != -1 && arrH.indexOf(this.pearlArr[this.pearlArr.length - 1][0].substr(0, 1)) != -1)
                            //     )) {//如果当前的露珠类型与上一项同类，则加到上一项的队列中
                            //     this.pearlArr[this.pearlArr.length - 1].push(data.pearl);
                            // } else {//否则新建自己的队列
                            //     this.pearlArr.push([data.pearl]);
                            // }
                            // drawCanvas(document.querySelectorAll(".pearl-two"),19,6);
                            // var str=JSON.stringify(this.pearlArr);
                            // var arr=JSON.parse(str);
                            // fillRoomPearl(document.querySelector(".pearl-two"),6,19,delPearlTie(arr.reverse(),19).reverse());
                            // arr=JSON.parse(str);
                            // this.drawTextBigPearl([].concat.apply([], arr).slice(-(52 - 1) * 6 - count % 6));
                        }
                        break;
                    default:
                        break;
                }
            })
            this.$root.ws.wsSend('all_getroompearl',1, this.tableId, 54,"table", this.$root.user.name, this.$root.user.guid);
        },
        methods: {
            /**绘制带字大眼路 */
            drawTextBigPearl(pearlArr) {
                var zColor="#ac0000";
                var xColor="#0000b2";
                var hColor="#007c00";
                var elem=document.querySelector(".pearl-one");
                var style=getComputedStyle(elem);
                var width=style.width.replace('px','');
                var height=style.height.replace('px','');
                var ctx = elem.getContext('2d');
                var cellHeight = height / 6;
                var cellWidth = width / Math.round(width / cellHeight);
                ctx.lineWidth = cellWidth * .08;//边框粗细
                ctx.font = "bold "+cellWidth * .7 + 'px SimHei';
                ctx.textBaseline = 'middle';
                var text = '';//填充文本
                var arr = [0, 0, 0, 0, 0];//庄，闲，和，庄对，闲对 的统计
                for (var i = 0; i < pearlArr.length; i++) {
                    //填充圆圈
                    var letter = pearlArr[i].substr(0, 1);//获取首字母
                    if (letter == 'H' || letter == 'N' || letter == 'G' || letter == 'I' || letter == 'B') {
                        ctx.fillStyle = ctx.strokeStyle = xColor;//填充颜色
                        text = letter == 'N' ? '虎' : '闲';
                    } else if (letter == 'D' || letter == 'M' || letter == 'F' || letter == 'E' || letter == 'A') {
                        ctx.fillStyle = ctx.strokeStyle = zColor;
                        text = letter == 'M' ? '龙' : '庄';
                    } else if (letter == 'L' || letter == 'J' || letter == 'K' || letter == 'O' || letter == 'C') {
                        ctx.fillStyle = ctx.strokeStyle = hColor;
                        text = '和';
                    }
                    ctx.beginPath();
                    ctx.arc((Math.ceil((i + 1) / 6) - 1) * (width/15) + (width/15 / 2), (i % 6) * cellHeight + cellHeight / 2, cellWidth / 2.3, 0, 2 * Math.PI);
                    //ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2, (i % 6) * cellHeight + cellHeight / 2, cellWidth / 2.3, 0, 2 * Math.PI);
                    ctx.stroke();
                    //填充字
                    ctx.fillText(text, (Math.ceil((i + 1) / 6) - 1) * (width/15) + (width/15 / 2) - ctx.measureText(text).width / 2, (i % 6) * cellHeight + cellHeight / 2);
                    //ctx.fillText(text, (Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, (i % 6) * cellHeight + cellHeight / 2);
                    //填充圆点
                    if (letter == 'D' || letter == 'L' || letter == 'G' || letter == 'I' || letter == 'F' || letter == 'J') {//含有庄对
                        ctx.fillStyle = zColor;
                        ctx.beginPath();
                        ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 - cellWidth / 3.5, (i % 6) * cellHeight + cellHeight / 2 - cellHeight / 3.5, cellWidth / 2.5 / 3.5, 0, 2 * Math.PI);
                        ctx.fill();
                    }
                    if (letter == 'H' || letter == 'L' || letter == 'I' || letter == 'F' || letter == 'K' || letter == 'E') {//含有闲对
                        ctx.fillStyle = xColor;
                        ctx.beginPath();
                        ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 + cellWidth / 3.5, (i % 6) * cellHeight + cellHeight / 2 - cellHeight / 3.5, cellWidth / 2.5 / 3.5, 0, 2 * Math.PI);
                        ctx.fill();
                    }
                    //数量统计
                    switch (letter) {
                        case 'D':
                            arr[0]++; arr[3]++; break;
                        case 'H':
                            arr[1]++; arr[4]++; break;
                        case 'L':
                            arr[2]++; arr[3]++; arr[4]++; break;
                        case 'G':
                            arr[1]++; arr[3]++; break;
                        case 'I':
                            arr[1]++; arr[3]++; arr[4]++; break;
                        case 'F':
                            arr[0]++; arr[3]++; arr[4]++; break;
                        case 'J':
                            arr[2]++; arr[3]++; break;
                        case 'K':
                            arr[2]++; arr[4]++; break;
                        case 'E':
                            arr[0]++; arr[4]++; break;
                        case 'O':
                        case 'C':
                            arr[2]++; break;
                        case 'A':
                        case 'M':
                            arr[0]++; break;
                        case 'B':
                        case 'N':
                            arr[1]++; break;
                    }
                }
                this.pearlInfo={
                    z:arr[0],
                    x:arr[1],
                    h:arr[2],
                    zd:arr[3],
                    xd:arr[4]
                }
            },
            /**
             * 绘制小路和下局预测
             * @param {Element} elem 要填充的画布DOM对象
             * @param {Array} arr 要填充的数组
             * @param {Number} type 要填充的类型(0=空心小眼路，不跳列 1=撇路，跳1列 2=实心小眼路，跳2列)
             */
            drawSmallPearl(elem, arr, type) {
                var zColor="#ac0000";
                var xColor="#0000b2";
                var hColor="#007c00";
                var ctx = elem.getContext('2d');
                var cellHeight = elem.height / 3;
                var cellWidth = elem.width / 26;
                ctx.lineWidth = cellWidth * .09;//边框粗细
                ctx.fillStyle="#000000";
                ctx.strokeStyle="#000000";
                if (arr && arr.length>0) {
                    var maxPoint = [0, 0, 0, 0, 0, 0];//保存每一行露珠达到的最大x坐标点
                    var lastPoint = 0;//保存上一次最后一行露珠的最大坐标点，用来比较是否可以左拐
                    var column = 0;//记录当前绘制到第几列
                    var currentRow = 0;//当前列的露珠已到达的行数
                    var count=0;
                    //为数组添加模拟值，当前为添加模拟闲，用于顺便处理下局开庄和下局开闲
                    var arrZ = ['D', 'M', 'F', 'E', 'A'];
                    var arrX = ['H', 'N', 'G', 'I', 'B'];
                    var expectColor = '#ffffff';//获取模拟值的颜色
                    if (arrX.indexOf(arr[arr.length - 1][arr[arr.length - 1].length - 1].substr(0, 1)) != -1) {//如果当前数组最后一项的元素的最后一项首字母为闲类
                        arr[arr.length - 1].push('B1');//则将模拟闲添加到当前数组最后一项的最后一个元素
                    } else {//为庄类
                        arr.push(['B1']);//将模拟闲添加为当前数组最后一项
                    }
                    
                    for (var i = 1 + type; i < arr.length; i++) {//从第二列开始遍历列
                        for (var j = 0; j < arr[i].length; j++) {//遍历行
                        // if(type==0){debugger}
                            var color = type == 2 ? ctx.fillStyle : ctx.strokeStyle;
                            //设置颜色
                            if ((i > 1 + type) && j == 0) {//从第3列开始，仅比较一次前两列个数
                                if (arr[i - 1].length == arr[i - (2 + type)].length) {//相同(红)
                                    expectColor = zColor;
                                } else {//不同(蓝)
                                    expectColor = xColor;
                                }
                                ctx.fillStyle = expectColor;
                                ctx.strokeStyle = expectColor;
                            } else if ((i > 0 + type) && j > 0 && arr[i][j]) {//从第2列第2行开始，如果有值，则对应上一列同行，
                                if (arr[i - (1 + type)][j]) {//有同行(红)
                                    expectColor = zColor;
                                } else {//无同行(蓝，且本列本行到本列结束皆为红)
                                    if (j > 1 && !arr[i - (1 + type)][j - 1]) {//有上一行并且上一行无对应的上一列同行
                                        expectColor = zColor;
                                    } else {
                                        expectColor = xColor;
                                    }
                                }
                                ctx.fillStyle = expectColor;
                                ctx.strokeStyle = expectColor;
                            } else {
                                continue;
                            }
                            //修改模拟值为庄
                            if (i == arr.length - 1 && j == arr[i].length - 1) {//遍历到模拟项
                                type == 0 && (arr[i][j] == 'B1' || arr[i][j] == 'N1') && (this.nextResCol.hollow_x={"border-color":expectColor});
                                type == 0 && (arr[i][j] == 'A1' || arr[i][j] == 'M1') && (this.nextResCol.hollow_z={"border-color":expectColor});
                                type == 1 && (arr[i][j] == 'B1' || arr[i][j] == 'N1') && (this.nextResCol.diagonal_x={"border-color":expectColor});
                                type == 1 && (arr[i][j] == 'A1' || arr[i][j] == 'M1') && (this.nextResCol.diagonal_z={"border-color":expectColor});
                                type == 2 && (arr[i][j] == 'B1' || arr[i][j] == 'N1') && (this.nextResCol.solid_x={"border-color":expectColor,"background":expectColor});
                                type == 2 && (arr[i][j] == 'A1' || arr[i][j] == 'M1') && (this.nextResCol.solid_z={"border-color":expectColor,"background":expectColor});
                                if (arr[i][j] == 'B1' || arr[i][j] == 'N1') {//如果模拟项为闲，则替换为模拟庄
                                    if (arr[arr.length - 1].length == 1) {//如果最后一列只有一个，则说明其为模拟项，将其移除并添加模拟庄
                                        arr.splice(arr.length - 1, 1);//移除
                                        arr[arr.length - 1].push('A1');//添加
                                        i--;//添加到上一列之后数组少一列，i必须减1
                                        j = arr[arr.length - 1].length - 2;//重新设置j
                                        continue;
                                    } else {//不只一个则需要先移除再添加新列
                                        arr[arr.length - 1].splice(arr[arr.length - 1].length - 1, 1);//移除
                                        arr.push(['A1']);//添加
                                        break;
                                    }
                                } else {//否则移除并终止循环
                                    if (arr[arr.length - 1].length == 1) {//如果最后一列只有一个，则说明其为模拟项
                                        arr.splice(arr.length - 1, 1);//直接将最后一列移除
                                    } else {//不只一个则移除最后一列的最后一个
                                        arr[arr.length - 1].splice(arr[arr.length - 1].length - 1, 1);
                                    }
                                    break;
                                }
                            }
                            //设置位置
                            if (type == 2) {//实心要比较填充
                                if (color != ctx.fillStyle && color != '#000000') {//颜色不同于上一次的颜色
                                    column++;
                                    currentRow = 0;
                                    count=0;
                                }
                            } else {//线或空心比较描边
                                if (color != ctx.strokeStyle && color != '#000000') {//颜色不同于上一次的颜色
                                    column++;
                                    currentRow = 0;
                                    count=0;
                                }
                            }
                            var x = column * cellWidth / 2 + (cellWidth / 4);//正常向下的x值
                            var y = currentRow * cellHeight / 2 + (cellHeight / 4);//正常向下的y值
                            if (currentRow == 6 || x <= maxPoint[currentRow]) {//已经到达最后一行或者当前要画的x坐标小于或等于当前要画行的最大坐标，即当前要画的位置已有露珠
                                //if (currentRow == 6 && lastPoint + 4 * cellWidth <= x) {//如果当前已到达第6行并且第6行露珠的x坐标大于该行最大坐标的8倍，即前面有7个空位，则向左拐
                                if (currentRow == 6 && lastPoint<x-(arr[i].length - 6) * cellWidth/2) {//如果当前已到达第6行并且第6行露珠的x坐标大于该行最大坐标的8倍，即前面有7个空位，则向左拐
                                    x = ((column-count) - ((currentRow + 1) - currentRow)) * cellWidth / 2 + (cellWidth / 4);//当前列数-
                                    count++;
                                } else {//否则右拐
                                    x = (column + currentRow - currentRow + 1) * cellWidth / 2 + (cellWidth / 4);//当前个数-当前所在行+当前列=要画的露珠列
                                    maxPoint[currentRow - 1] = x;//更新最大坐标点
                                }
                                y = (currentRow - 1) * cellHeight / 2 + (cellHeight / 4);
                            } else {//否则向下画
                                currentRow == 5 && (lastPoint = maxPoint[currentRow] == 0 ? cellWidth / -4 : maxPoint[currentRow]);//如果已是最后一行，则保存最大坐标点，用于左拐判断
                                maxPoint[currentRow] = x;//更新最大坐标点
                                currentRow++;//累加当前列露珠到达的行数
                            }
                            //绘制圆圈/斜线
                            ctx.beginPath();
                            if (type == 1) {
                                ctx.moveTo(x - cellWidth / 4 / (2.5 / 1.8), y + cellHeight / 4 / (2.5 / 1.8));
                                ctx.lineTo(x + cellWidth / 4 / (2.5 / 1.8), y - cellHeight / 4 / (2.5 / 1.8));
                            } else {
                                ctx.arc(x, y, cellWidth / 2 / (2.5/.9), 0, 2 * Math.PI);
                            }
                            if (type==2) {//实心
                                ctx.fill();
                            } else {//描边
                                ctx.stroke();
                            }
                        }
                    }
                }
            },
            /**绘制排序大眼路 */
            drawOrderBigPearl(pearlArr) {
                var zColor="#ac0000";
                var xColor="#0000b2";
                //处理数组
                var array = pearlArr.slice();
                array.reverse();
                var arr = [];
                var count = 0;//arr中的数据个数
                for (var i = 0; i < array.length; i++) {
                    if (array[i].length + count <= 26 * 3 - 1) {//当前元素加进去不会超出显示数量
                        arr.push(array[i]);
                        var letter = array[i][0].substr(0, 1);
                        (letter != 'L' && letter != 'N' && letter != 'J' && letter != 'K' && letter != 'O' && letter != 'C') && (count += array[i].length);
                    } else {//加进去超出显示数量（分为全部超出或部分超出）
                        if (count != 26 * 3 - 1) {//部分超出
                            arr.push(array[i].slice(0, 26 * 3 - 1 - count));
                        }
                        break;
                    }
                }
                arr = [].concat.apply([], delPearlTie(arr, arr.length,false).reverse());
                //绘制
                var elem=document.querySelector(".pearl-six");
                var ctx = elem.getContext('2d');
                var cellHeight = elem.height / 3;
                var cellWidth = elem.width / 26;
                ctx.lineWidth = cellWidth * .12;//边框粗细
                ctx.textBaseline = 'middle';
                ctx.font = 'bold '+cellWidth * .6 + 'px SimHei';
                for (var i = 0; i < arr.length; i++) {
                    //设置圆圈颜色
                    var letter = arr[i].substr(0, 1);//获取首字母
                    if (letter == 'H' || letter == 'N' || letter == 'G' || letter == 'I' || letter == 'B') {
                        ctx.fillStyle = ctx.strokeStyle = xColor;//填充颜色
                    } else if (letter == 'D' || letter == 'M' || letter == 'F' || letter == 'E' || letter == 'A') {
                        ctx.fillStyle = ctx.strokeStyle = zColor;
                    }
                    ctx.beginPath();
                    ctx.arc((Math.ceil((i + 1) / 3) - 1) * cellWidth + cellWidth / 2, (i % 3) * cellHeight + cellHeight / 2, cellWidth / 2.5, 0, 2 * Math.PI);
                    ctx.stroke();
                    //绘制斜线和数字
                    if (arr[i].length == 3) {//当前遍历到的项字符串长度为3
                        var text = arr[i].substr(2, 1);
                        ctx.fillText(text, (Math.ceil((i + 1) / 3) - 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, (i % 3) * cellHeight + cellHeight / 2);
                        ctx.lineWidth = cellWidth * .12;//边框粗细
                        ctx.moveTo((Math.ceil((i + 1) / 3) - 1) * cellWidth + cellWidth / 2, (i % 3) * cellHeight + cellHeight / 2 + cellHeight / 2.5);//向左拐
                        ctx.lineTo((Math.ceil((i + 1) / 3) - 1) * cellWidth + cellWidth / 2 + cellHeight / 2.5, (i % 3) * cellHeight + cellHeight / 2);
                        ctx.stroke();
                    }
                }
            },
            /**显示问路(type:问路类型，A为庄，B为闲，M为龙，N为虎) */
            showCalculate(type){
                if(!this.isFlicker){//没有在闪烁时
                    this.isFlicker=true;
                    var str=JSON.stringify(this.pearlArr);
                    var arr=JSON.parse(str);
                    //将模拟的问路类型加入数组
                    var arrZ = ['D', 'M', 'F', 'E', 'A'];
                    var arrX = ['H', 'N', 'G', 'I', 'B'];
                    var arrH = ['L', 'J', 'K', 'O', 'C'];
                    if (arr && arr.length > 0 && (
                        (arrZ.indexOf(type) != -1 && arrZ.indexOf(arr[arr.length - 1][0].substr(0, 1)) != -1) ||
                        (arrX.indexOf(type) != -1 && arrX.indexOf(arr[arr.length - 1][0].substr(0, 1)) != -1) ||
                        (arrH.indexOf(type) != -1 && arrH.indexOf(arr[arr.length - 1][0].substr(0, 1)) != -1)
                        )) {//如果当前的露珠类型与上一项同类，则加到上一项的队列中
                        arr[arr.length - 1].push(type + '1');
                    } else {//否则新建自己的队列
                        arr.push([type + '1']);
                    }
                    var sum = 0;//用于控制显示或者隐藏，奇数显示，偶数隐藏
                    var count = 1;
                    for (const key in this.pearlInfo) {
                        count+=Number(this.pearlInfo[key]);
                    }
                    var time = setInterval(function () {
                        if(!this.isFlicker){
                            clearInterval(time);
                        }
                        sum++;
                        if (sum % 2 != 0) {//奇数
                            //显示
                            drawCanvas(document.querySelectorAll(".pearl-one"),15,6);
                            this.drawTextBigPearl([].concat.apply([], JSON.parse(JSON.stringify(arr))).slice(-(84 + count % 6)));
                            drawCanvas(document.querySelectorAll(".pearl-two"),52,6);
                            drawCanvas(document.querySelectorAll(".pearl-three,.pearl-four,.pearl-five,.pearl-six"),26,3);
                            fillRoomPearl(document.querySelector(".pearl-two"),6,52,delPearlTie(JSON.parse(JSON.stringify(arr)).reverse(),52).reverse());
                            this.drawOrderBigPearl(JSON.parse(JSON.stringify(arr)));
                            var array=delPearlTie(JSON.parse(JSON.stringify(arr)).reverse(),52).reverse();
                            this.drawSmallPearl(document.querySelector(".pearl-three"),array,0);
                            this.drawSmallPearl(document.querySelector(".pearl-five"), array, 1);
                            this.drawSmallPearl(document.querySelector(".pearl-four"), array, 2);
                        } else {//偶数
                            //隐藏
                            drawCanvas(document.querySelectorAll(".pearl-one"),15,6);
                            this.drawTextBigPearl([].concat.apply([], JSON.parse(JSON.stringify(this.pearlArr))).slice(-(84 + count % 6)));
                            drawCanvas(document.querySelectorAll(".pearl-two"),52,6);
                            drawCanvas(document.querySelectorAll(".pearl-three,.pearl-four,.pearl-five,.pearl-six"),26,3);
                            fillRoomPearl(document.querySelector(".pearl-two"),6,52,delPearlTie(JSON.parse(JSON.stringify(this.pearlArr)).reverse(),52).reverse());
                            this.drawOrderBigPearl(JSON.parse(JSON.stringify(this.pearlArr)));
                            var array=delPearlTie(JSON.parse(JSON.stringify(this.pearlArr)).reverse(),52).reverse();
                            this.drawSmallPearl(document.querySelector(".pearl-three"),array,0);
                            this.drawSmallPearl(document.querySelector(".pearl-five"), array, 1);
                            this.drawSmallPearl(document.querySelector(".pearl-four"), array, 2);
                        }
                        if (sum == 10) {//10为闪5次
                            clearInterval(time);
                            this.isFlicker=false;
                        }
                    }.bind(this), 500)
                }
            }
        },
        watch: {
            tableId(newValue, oldValue) {
                this.$root.ws.wsSend('all_getroompearl',1, this.tableId, 52,"table", this.$root.user.name, this.$root.user.guid);
            },
        },
    }
</script>