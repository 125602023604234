<style scoped>
	.media-min{
		width:14rem;
		height: 10rem;
	}
	.index-1071{
		z-index: 1071;
	}
</style>
<template>
	<div class="container-lg py-sm-2 px-0 px-sm-2 h-100 position-relative">
		<!-- <button class="btn" @click="customService" style="color: #fff;background-color: #0d6efd;border-color: #0d6efd;margin-top: 3rem;">联系客服</button> -->
		<div class="card bg-secondary border-0 h-100 bg-opacity-50">
			<div class="card-header bg-dark bg-gradient text-white d-flex justify-content-between justify-content-sm-end">
				<span class="d-sm-none">{{user.name}}</span>
				<i class="bi bi-x-square-fill" @click="$emit('closeChat')" role="button"></i>
			</div>
			<div class="card-body overflow-hidden p-0 p-sm-2 p-lg-3">
				<div class="d-flex h-100">
					<!-- 好友列表 -->
					<div v-if="user.type==userTypeConfig.server" :class="{'d-none d-sm-flex':currFriend!=''}" class="card bg-light border-0 me-0 me-sm-2 me-lg-3 h-100 col-12 col-sm-4 col-md-3 rounded-xs-0 rounded-bottom">
						<div class="card-header bg-dark bg-gradient text-light d-none d-sm-block">{{user.name}}</div>
						<div class="card-body p-0">
							<div class="p-2">
								<div class="d-flex border rounded-pill">
									<i class="bi bi-search d-flex align-items-center ps-3 pe-2"></i>
									<input class="form-control form-control-sm shadow-none border-0 rounded-pill bg-light" type="text" v-model="txtSearch" placeholder="搜索">
								</div>
							</div>
							<div class="list-group list-group-flush">
								<load v-if="isShowFriendLoad"></load>
								<template v-else-if="(!isShowFriendLoad)&&friend.length>0">
									<a href="#" v-for="(item) in friend" :key="item['id']" :class="{'active':currFriend['id']==item['id']}" class="list-group-item list-group-item-action list-group-item-dark px-2" @click.prevent="currFriend=item" aria-current="true">
										<div class="d-flex">
											<i :class="{'text-muted':!item['online']}" class="bi bi-person-square me-2 fs-1 d-flex flex-column justify-content-center"></i>
											<div class="flex-grow-1 d-flex flex-column">
												<div class="d-flex justify-content-between">
													<small :class="{'text-muted':!item['online']}">{{item['name']}}</small>
													<small class="text-muted">
														<small>{{item['date']}}</small>
													</small>
												</div>
												<div class="d-flex w-100 justify-content-between align-items-center">
													<div class="text-truncate w-0 flex-grow-1">
														<label class="text-muted small lh-1">{{item['msg']}}</label>
													</div>
													<div class="d-flex flex-column justify-content-center">
														<small v-show="item['unread']>0" class="badge bg-danger rounded-pill">{{item['unread']}}</small>
													</div>
												</div>
											</div>
										</div>
									</a>
								</template>
								<small v-else class="text-muted text-center px-2">居然一个人都没有~</small>
							</div>
						</div>
					</div>
					<!-- 聊天窗口 -->
					<div class="flex-grow-1 col-12 col-sm-auto">
						<div class="card bg-light border-0 h-100">
							<div class="card-header bg-dark bg-gradient text-light text-center">
								<div class="d-flex">
									<i v-if="user.type==userTypeConfig.server" class="bi bi-chevron-left" @click="currFriend=''" role="button"></i>
									<div class="flex-grow-1">
										<label>{{friendName}}</label>
										<label v-if="!(user.type==userTypeConfig.server&&currFriend=='')" :class="[currFriend['online']==1?'text-success':'text-muted']">{{currFriend['online']==1?'【在线】':'【离线】'}}</label>
									</div>
								</div>
							</div>
							<div class="card-body p-0">
								<div class="d-flex flex-column h-100">
									<!-- 聊天记录 -->
									<div class="d-flex flex-column flex-grow-1 h-0 overflow-y-scroll scroll-none py-2" v-scroll-down>
										<load v-if="isShowLogLoad"></load>
										<template v-else-if="(!isShowLogLoad)&&currLog.length>0">
											<template v-for="(item,i) in currLog" :key="item">
												<!-- 文本消息展示 -->
												<template v-if="item['type']==msgTypeConfig.text">
													<!-- 时间 -->
													<div v-if="!(currLog[i-1]&&item['date']==currLog[i-1]['date'])" class="text-center small my-2">
														<small class="rounded-1 bg-secondary bg-opacity-50 text-white py-1 px-2">{{item['date']}}</small>
													</div>
													<!-- 消息 -->
													<div :class="{'justify-content-end':item['from']==user.id||(item['from']!=user.id&&item['from']!=currFriend['id']&&user.type!=userTypeConfig.client)}" class="d-flex my-2">
														<!-- 头像 -->
														<i :class="{'order-last':item['from']==user.id||(item['from']!=user.id&&item['from']!=currFriend['id']&&user.type!=userTypeConfig.client)}" class="bi bi-person-square mx-3 fs-1 d-flex"></i>
														<!-- 内容 -->
														<div :class="[(item['from']==user.id||(item['from']!=user.id&&item['from']!=currFriend['id']&&user.type!=userTypeConfig.client))?'bg-primary bs-popover-start':'bg-secondary bs-popover-end']" class="rounded-1 text-light position-relative popover p-2 d-flex align-items-center text-break">
															<div :class="[(item['from']==user.id||(item['from']!=user.id&&item['from']!=currFriend['id']&&user.type!=userTypeConfig.client))?'border-start-after-primary':'border-end-after-secondary']" class="position-absolute popover-arrow top-0 mt-2"></div>
															<span>{{item['msg']}}</span>
														</div>
														<!-- 加载效果 -->
														<load v-if="item['msgid']" class="order-first justify-content-center mx-2" spinner-class="spinner-border-sm"></load>
													</div>
												</template>
												<template v-if="item['type']==msgTypeConfig.system">
													<div class="d-flex justify-content-center">
														<div class="alert alert-success alert-dismissible fade show mb-0 py-2 px-3 small">
															<strong>系统提示：</strong> {{ item['msg'] }}
														</div>
													</div>
												</template>
												<template v-if="item['type']==msgTypeConfig.call">
													<div :class="{'justify-content-end':item['from']==user.id}" class="d-flex my-2">
														<i :class="{'order-last':item['from']==user.id}" class="bi bi-person-square mx-3 fs-1 d-flex"></i>
														<div :class="[item['from']==user.id?'bg-primary bs-popover-start':'bg-secondary bs-popover-end']" class="rounded-1 text-light position-relative popover p-2 d-flex align-items-center text-break">
															<div :class="[item['from']==user.id?'border-start-after-primary':'border-end-after-secondary']" class="position-absolute popover-arrow top-0 mt-2"></div>
															<div class="d-flex flex-column">
																<div class="d-flex px-1 justify-content-between" @click="enterCall(item['from'])" role="button">
																	<i class="bi bi-telephone-fill d-flex justify-content-center flex-column me-2"></i>
																	<span>进入通话<i class="bi bi-chevron-double-right"></i></span>
																</div>
																<div v-if="user.type==userTypeConfig.server" class="mt-1 pt-1 px-1 border-top">
																	{{ mediaUrl }}
																	<!-- <span>{{location.origin+"/#/media/"+this.user.id+"/"+this.currFriend["id"]}}</span> -->
																	<span class="btn-link ms-2" @click="copyMediaUrl" role="button">复制</span>
																</div>
															</div>
														</div>
													</div>
												</template>
											</template>
										</template>
										<div v-else class="d-flex flex-column text-muted h-100 justify-content-center align-items-center">
											<i class="bi bi-file-earmark-text-fill fs-3"></i>
											<span>空空如也</span>
										</div>
									</div>
									<!-- 操作栏 -->
									<div class="d-flex flex-column">
										<!-- 工具条 -->
										<div class="d-flex bg-dark text-light px-3 bg-gradient">
											<i class="bi bi-emoji-smile-fill d-flex p-2" :class="{'disabled':isEditDisabled}" title="表情" role="button"></i>
											<i v-if="user.type==userTypeConfig.server" class="bi bi-telephone-fill d-flex p-2" :class="{'disabled':isEditDisabled}" @click="sendMsg(msgTypeConfig.call)" title="通话聊天" role="button"></i>
											<!-- <i v-if="user.type==userTypeConfig.server" class="bi bi-camera-video-fill d-flex p-2" :class="{'disabled':isEditDisabled}" @click="sendMsg(msgTypeConfig.call)" title="视频通话" role="button"></i> -->
										</div>
										<!-- 输入栏 -->
										<div class="d-flex flex-column">
											<div class="rounded-top">
												<textarea v-focus class="form-control form-control-nofocus border-0 textarea-noresize shadow-none bg-light" :disabled="isEditDisabled" v-model="txtContent" rows="3" @keydown.enter.prevent="sendMsg(msgTypeConfig.text)"></textarea>
											</div>
											<div class="d-flex justify-content-end p-2" :class="{'disabled':isEditDisabled}">
												<div class="dropup me-2">
													<button type="button" class="btn btn-sm btn-secondary" data-bs-toggle="dropdown">
														<i class="bi bi-chat-square-dots"></i>
													</button>
													<div class="dropdown-menu py-0 mb-1 overflow-hidden">
														<ul class="list-group list-group-flush small text-nowrap">
															<li v-for="item of quickMsgConfig" :key="item" class="list-group-item list-group-item-action py-1" @click="sendMsg(msgTypeConfig.text,item)" role="button">{{item}}</li>
														</ul>
													</div>
												</div>
												
												<button type="button" :class="{'disabled':txtContent.trim()==''}" class="btn btn-sm btn-success px-3" :disabled="txtContent.trim()==''" @click="sendMsg(msgTypeConfig.text)">
													<i class="bi bi-send-fill me-1"></i>
													<span class="me-1">发 送</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div> 
						</div>
					</div>
				</div>
			</div> 
		</div>
		<audio ref="audio">
			<source :src="currAudioSrc" type="audio/mpeg">
			<embed :src="currAudioSrc">
		</audio>
		<div v-if="isShowMediaChat" :class="[isMediaMin?'media-min start-0 top-0 fs-6':'start-0 top-0 fs-3']" class="position-absolute end-0 bottom-0 rounded-3 overflow-hidden index-1071">
			<media-chat class="" :is-hidden-other="isMediaMin" :local-id="user.id" :peer-id="peerId" @close-chat="isShowMediaChat=false" @chat-msg-websocket="chatMsgWebsocket"></media-chat>
			<div class="position-absolute top-0 start-0 text-light fs-3 pt-2 ps-3">
				<i class="bi bi-aspect-ratio d-flex" @click="isMediaMin=!isMediaMin" role="button"></i>
			</div>
		</div>
	</div>
</template>
<script>
import Load from "@/components/public/Load.vue";
import MediaChat from "@/components/public/MediaChat.vue";
import { INTERFACE,USER_TYPE,MSG_TYPE,SEND_RESULT,ONLINE_TYPE,MEDIA_TYPE,AUDIO_NAME } from "@/assets/js/const.js";
import { guid,getDate,copy } from "@/assets/js/common.js";
export default {
	emits: ['msgWebsocket','sendWebsocket','closeWebsocket','closeChat'],
	components: { Load,MediaChat },
	directives: {
        'scroll-down': {
			mounted(elem) {
				elem.scrollTop = elem.scrollHeight;//将滚动条自动置于底部
			},
            updated(elem){
                elem.scrollTop = elem.scrollHeight;//将滚动条自动置于底部
            }
        }
    },
	data() {
		return {
			userTypeConfig:USER_TYPE,//获取用户类型配置
			msgTypeConfig:MSG_TYPE,//获取消息类型配置
			quickMsgConfig:config().msg.client,//获取快捷消息配置
			mediaTypeConfig:MEDIA_TYPE,
			txtContent: "",//要发送的消息
			isShowFriendLoad:true,//是否显示用户列表加载效果
			friendList:[],//用户列表
			currFriend:"",//当前选中的好友
			isShowLogLoad:false,//是否显示聊天记录加载效果
			currLog:[],//当前聊天记录
			txtSearch:"",//搜索内容
			user:{
				id:history.state.id,
				name:history.state.name,
				type:history.state.type,
				guid:history.state.guid
			},//当前用户信息
			sendQueue:[],//发送消息队列
			//要播放的音效列表
			audioList:{
				[AUDIO_NAME.up]:require("@/assets/media/up.mp3"),
				[AUDIO_NAME.msg]:require("@/assets/media/msg.mp3")
			},
			currAudioSrc:"",//当前音效地址
			isMediaMin:false,//媒体通话视频是否缩小
			isShowMediaChat:false,//是否显示媒体聊天窗
			peerId:null,//对方id
			chatMsgFn:null,
		};
	},
	computed: {
		/**
		 * 好友列表
		 */
		friend() {
			return this.txtSearch.trim()==""?this.friendList:this.friendList.filter(v=>v["name"].indexOf(this.txtSearch)!=-1);
		},
		/**
		 * 编辑区是否禁用
		 */
		isEditDisabled(){
			if(this.user.type==USER_TYPE.server){
				return this.currFriend!=""?false:true;
			}
		},
		/**
		 * 好友名称显示
		 */
		friendName(){
			let name="";
			if(this.user.type==USER_TYPE.client){
				name="客服";
			}else{
				name=this.currFriend['name'];
			}
			return name;
		},
		/**
		 * 获取当前好友最后一条消息
		 */
		getLastLog(){
			return this.currLog.find(v=>v['from']==this.user.id);
		},
		/**
		 * 媒体聊天地址
		 */
		mediaUrl(){
			return location.origin+"/#/media/"+this.user.id+"/"+this.currFriend["id"];
		}
	},
	mounted() {
		//发送心跳 
		this.$emit("sendWebsocket",{
			fun:INTERFACE.online,
			id:this.user.id,
			type:this.user.type
		})

		// 获取用户列表
		this.$emit("sendWebsocket",{
			fun:INTERFACE.getfriend,
			id:this.user.id,
			type:this.user.type,
			guid:this.user.guid
		})

		//客户模式则直接获取聊天记录
		this.user.type==USER_TYPE.client&&this.$emit("sendWebsocket",{
			fun:INTERFACE.getlog,
			cid:this.user.id,
			guid:this.user.guid
		})

		//绑定消息响应事件
		this.$emit("msgWebsocket",(data)=>{
			switch (data["fun"]) {
				case INTERFACE.getfriend:
					this.friendList=data["list"];
					this.isShowFriendLoad=false;
					//客户模式并且没有好友
					if(this.user.type==USER_TYPE.client){
						if(this.friendList.length>0){//有好友
							this.currFriend=this.friendList[0];
						}else{//没有好友
							// this.currLog.push({
							// 	type:MSG_TYPE.system,
							// 	msg:"抱歉！暂时没有客服在线"
							// })
						}
					}
					break;
				case INTERFACE.updown:
					let friend = this.friendList.find(v=>v["id"]==data["id"]);
					//上线
					if(data["type"]==ONLINE_TYPE.on){
						if(!friend){//好友列表中没有则加入好友列表
							if(this.user.type==USER_TYPE.client){//客户模式
								friend={
									id:data["id"],
									name:data["name"],
									online:1,
									unread:0
								}
								if(this.friendList.length==0){//初次匹配客服
									this.friendList.length==0&&this.currLog.push({
										from:data["id"],
										fname:data["name"],
										to:this.user.id,
										tname:this.user.name,
										type:MSG_TYPE.text,
										msg:"您好，有什么可以帮到您吗？",
										date:getDate()
									})
									this.playAudio(AUDIO_NAME.up);
								}else{//二次更换客服
									
								}
								this.friendList=[friend];
								this.currFriend=friend;//当前只有一个好友，自动选中当前上线好友为聊天好友
							}
						}else{//好友列表中有则修改在线状态
							this.playAudio(AUDIO_NAME.up);
							friend["online"]=1;
						}
					}else if(data["type"]==ONLINE_TYPE.off){//下线
						friend["online"]=0;
						if(this.user.type==USER_TYPE.client){//客户模式
							this.friendList=[];
						}
					}
					break;
				case INTERFACE.getlog:
					this.isShowLogLoad=false;
					if(data["list"].length>0){
						this.setFriendLastMsg(this.currFriend["id"],data["list"][0]["type"],data["list"][0]["msg"],data["list"][0]["date"]);
						this.currLog=data["list"].reverse();
					}
					//客户模式下并且有好友
					if(this.user.type==USER_TYPE.client&&this.friendList.length>0){
						this.currLog.push({
							from:this.currFriend["id"],
							fname:this.currFriend["name"],
							to:this.user.id,
							tname:this.user.name,
							type:MSG_TYPE.text,
							msg:"您好，有什么可以帮到您吗？",
							date:getDate()
						})
					}
					break;
				case INTERFACE.send:
					let sendResult=data["result"];
					if(sendResult==SEND_RESULT.success){
						let log=this.currLog.find(v=>v["msgid"]==data["msgid"]);
						if(log){
							delete log["msgid"];
						}
					}else if(sendResult==SEND_RESULT.error){
						useLayer({str:"发送失败"});
					}else if(sendResult==SEND_RESULT.repeat){
						useLayer({str:"重复发送"});
					}
					break;
				case INTERFACE.msg:
					let type=data["type"];
					let msgFriend = this.friendList.find(v=>v["id"]==data["from"]);
					let date=getDate();
					this.playAudio(AUDIO_NAME.msg);
					if(msgFriend){//列表中已有的好友
						//列表消息变更
						this.setFriendLastMsg(data["from"],data["type"],data["msg"],date);
						if(data["from"]==this.currFriend["id"]){//接收的消息为当前聊天窗口好友发来的
							//if(type==MSG_TYPE.text){
								// 消息加入聊天面板
								this.currLog.push({
									from:data["from"],
									fname:data["fname"],
									to:data["to"],
									tname:data["tname"],
									type:data["type"],
									msg:data["msg"],
									date
								})
							//}
						}else{//非当前聊天窗口好友
							msgFriend["unread"]++;//累加未读消息
						}
					}else{//列表中没有的好友
						this.friendList.unshift({
							id:data["from"],
							name:data["fname"],
							online:1,
							unread:1,
							msg:data["msg"],
							msgtype:data["type"]
						});
					}
					break;
				case INTERFACE.online:
					setTimeout(() => {
						//发送心跳
						this.$emit("sendWebsocket",{
							fun:INTERFACE.online,
							id:this.user.id,
							type:this.user.type
						})
					}, 20000);
					break;
			}
			this.chatMsgFn&&this.chatMsgFn(data);
		})
	},
	unmounted() {
		this.$emit("closeWebsocket");
	},
	methods:{
		chatMsgWebsocket(fn){
			this.chatMsgFn=fn;
		},
		/**
		 * 发送消息
		 * @param {MSG_TYPE} type 消息类型
		 * @param {String} msg 消息
		 */
		sendMsg(type,msg=""){
			let content=this.txtContent.trim();//要显示的内容
			let obj={
				from:this.user.id,
				fname:this.user.name,
				to:this.currFriend["id"]||"",
				tname:this.currFriend["name"]||"",
				type:type,
				msg:msg,//要发送的内容
				msgid:guid()
			}
			let date=getDate();
			if(type==MSG_TYPE.text){
				if(msg!=""){//发快捷消息
					content=msg;
				}else{//发编辑区文本
					obj.msg=content;
				}
				if(content==""){
					useLayer({
						str:"不能发送空消息"
					});
					return;
				}
				//清空编辑区
				this.txtContent="";
			}else if(type==MSG_TYPE.call){
				content="[通话聊天]";
			}
			//消息加入聊天面板
			this.currLog.push(Object.assign(JSON.parse(JSON.stringify(obj)),{
				date:date
			}))
			//列表消息变更
			this.user.type==USER_TYPE.server&&this.setFriendLastMsg(this.currFriend["id"],MSG_TYPE.text,content,date);
			//消息加入待发送队列
			this.sendQueue.push(Object.assign(JSON.parse(JSON.stringify(obj)),{
				fun:INTERFACE.send,
				guid:this.user.guid,
			}))
		},
		/**
		 * 好友列表消息变更
		 * @param {String} id 好友id
		 * @param {MSG_TYPE} type 消息类型
		 * @param {String} msg 消息
		 */
		setFriendLastMsg(id,type,msg,date){
			let friend = this.friendList.find(v=>v["id"]==id);
			let dataArr=getDate().split(' ');
			if(friend){
				friend["msg"]=msg;
				friend["msgtype"]=type;
				if(date.split(' ')[0]==dataArr[0]){//日期与当前日期一致
					friend["date"]=dataArr[1];//则使用时间
				}else{
					friend["date"]=dataArr[0];//否则使用日期
				}
			}
		},
		/**
		 * 进入媒体聊天
		 */
		enterCall(peerId){
			if(this.user.type==USER_TYPE.client){
				// this.$router.resolve('/media/'+this.user.id);
				// open(location.origin+"/#/media/"+this.user.id);
				this.isShowMediaChat=true;
				this.peerId=peerId;
			}else if(this.user.type==USER_TYPE.server){
				this.peerId=this.currFriend["id"];
				this.isShowMediaChat=true;
				// open(location.origin+"/#/media/"+this.user.id+"/"+this.currFriend["id"]);
			}
		},
		/**
		 * 复制媒体聊天地址
		 */
		copyMediaUrl(){
			copy(this.mediaUrl).then((result)=>{
				if(result=="success"){
					useLayer({str:"已复制到剪贴板"});
				}else{
					useLayer({str:"复制失败"});
				}
			});
		},
		/**
		 * 播放音效
		 * @param {AUDIO_NAME} name 音效名称
		 */
		playAudio(name){
			if(name==this.currAudioName){//要播放的音效为当前播放器中的音效
				this.$refs.audio.play();
			}else{
				this.currAudioName=name;
				this.currAudioSrc=this.audioList[name];
			}
		},
	},
	watch: {
		/**
		 * 改变当前聊天好友
		 */
		currFriend(newVal) {
			if(newVal!=""){
				let cid,sid="";
				if(this.user.type==USER_TYPE.client){//当前是客户
					cid=this.user.id;
				}else if(this.user.type==USER_TYPE.server){//当前是客服
					this.isShowLogLoad=true;
					cid=this.currFriend["id"];
					sid=this.user.id;
					let friend = this.friendList.find(v=>v["id"]==cid);
					friend["unread"]=0;
					this.$emit("sendWebsocket",{
						fun:INTERFACE.getlog,
						cid,
						sid,
						guid:this.user.guid
					})
				}
			}else{//关闭当前聊天窗口
				this.currLog=[];//清空记录
				this.txtContent="";//清空编辑区
			}
		},
		/**
		 * 发送消息队列，可以实现一次发很多消息然后依次被异步加载发送
		 */
		sendQueue:{
			handler(newVal){
				if(newVal.length>0){//队列中存在未发送的消息
					let queue=JSON.parse(JSON.stringify(newVal));//创建副本
					this.sendQueue=[];//清空队列
					for (let i = 0; i < queue.length; i++) {
						this.$emit("sendWebsocket",queue[i]);
					}
				}
			},
			deep:true
		},
		/**
		 * 改变当前音频播放地址
		 * @param {String} newVal 新地址
		 */
		currAudioSrc(newVal){
			this.$nextTick(()=>{
				this.$refs.audio.load();
				this.$refs.audio.play();
			})
		}
	},
}
</script>
