<template>
    <div v-if="$root.ws" class="table-frame">
        
    </div>
</template>
<script>
    import VideoPlayer from '@/components/public/VideoPlayer.vue';
    export default{
        components:{
            VideoPlayer,
            // PearlHistory,
        },
        props: {
            encrystr:""
        },
    }
</script>