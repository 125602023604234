<style scoped>
    .navbar-frame{
        position: relative;
    }
    .logo{
        width: 100%;
        height: 23%;

    }
    .logo img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
        display: block;
    }
    .user-border{
        width: 80%;
        margin: 0 auto;
        border: 2px transparent solid;
        border-image: linear-gradient(45deg,#521104 0%,#F9E696 50%,#AD661A 100%) 1 10;
    }
    .user-text{
        margin: 2%;
        display: block;
        color: #FFF;
        background: #000;
        font-size: .7em;
        padding: 4% 0 4% 4%;
        border-radius: .2em;
    }
    .user-text>label{
        color: #fbfb00;
    }
    .navbar-type{
        width: 80%;
        height: 12%;
        margin-bottom: 1%;
        color: #4d3300;
        font-size: 1em;
        border-radius: .3em;
        background-image: linear-gradient(0deg,#bc9a50 0%,#fcfcd1 50%,#ad8328 100%);
        border:2px solid #4d3301;
        text-shadow: .06em .06em #e7e3b0;
    }
    .line-group{
        width: 94%;
        font-size: .5em;
    }
    .line-name{
        width: 80%;
        padding: 1%;
        border-radius: .2em;
        margin: 0 auto;
        background-image: linear-gradient(180deg,#666 0%,#C9C9C9 30%,#4c4c4c 60%);
        display: block;
        cursor: pointer;
        text-align: center;
    }
    .line-list{
        width: 80%;
        margin: 0 auto;
        background-color: #000;
        overflow: hidden;
    }
    .line-item{
        width: 100%;
        color: #fff;
        text-align: center;
        display: block;
        padding: 2% 0;
        cursor: pointer;
    }
    a{ 
        text-decoration:none;
    }
    a:hover { 
        text-decoration:none;
    }
    /* sir */
    .navbar-type{
        cursor: pointer;
    }
    .line-pagination{
        text-align: center;
        margin-bottom: 5%;
    }
    .line-page{
        border: .8em solid transparent;
        cursor: pointer;
        width: 0;
        border-top-width: .5em;
        border-bottom-width: .5em;
        display: inline-block;
        vertical-align: middle;
        line-height: 100%;
    }
    .line-left{
        border-left: 0;
        border-right-color: #c1791f;
    }
    .line-right{
        border-right: 0;
        border-left-color: #c1791f;
    }
    .line-pagination>span{
        background: #fff;
        margin: 0 15%;
        vertical-align: middle;
        display: inline-block;
    }
    .navbar-list{
        font-size: .7em;
    }
</style>
<template>
    <div class="navbar-frame align-content-start justify-content-center">
        <div class="logo d-flex flex-column justify-content-center">
            <img class="img-fluid" src="..\..\..\public\images\logo.png">
        </div>
        <div class="userInfo w-100 py-5">
            <div class="user-border">
                <span class="user-text">账户：<label id="userID">{{userName}}</label></span>
            </div>
        </div>
        <div class="navbar-list text-center">
            <button class="navbar-type mb-2" type="button" @click="showFullScreen">全屏显示</button>
            <button class="navbar-type mb-2" type="button" @click="showAgreement">协议条款</button>
            <button class="navbar-type mb-2" type="button" @click="exitGame">离开游戏</button>
        </div>
        <div class="line-group pt-2">
            <label @click="showLine" class="line-name py-1">{{currentLine.name}}</label>
            <div v-show="isShowLine" data-hidden="slide" class="line-list" >
                <div v-for="item in lineArr.slice(6*(currentLinePage-1),6*currentLinePage)" :key="item" @click="chooseLine(item.name,item.id)" class="line-item">{{item.name}}</div>
                <div class="line-pagination">
                    <div @click="changeLinePage(-1)" class="line-page line-left"></div>
                    <span><span>{{currentLinePage}}</span>/<span>{{Math.ceil(lineArr.length/6)}}</span></span>
                    <div @click="changeLinePage(1)" class="line-page line-right"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { fullScreen } from '@/assets/js/common.js';
    export default{
        props:{
            /**
             * 当前线路对象
             */
            currentLine:{
                type:Object,
                default:{
                    id:"",
                    name:""
                }
            },
            /**
             * 线路列表
             */
            lineArr:{
                type:Array,
                default:[]
            }
        },
        data() {
            return {
                userName:"",//账号
                isShowLine: false,//是否显示线路列表
                currentLinePage:1,//当前线路列表页码
            }
        },
        mounted(){
            this.$root.addMessage("navbar",(data) => {
                switch (data.command) {
                    case "all_getuserinfo"://获取用户信息
                        this.userName=data.userid;
                        break;
                    default:
                        break;
                }
            })
            this.$root.ws.wsSend('all_getuserinfo', this.$root.user.name, this.$root.user.guid);
        },
        methods: {
            /**点击离开游戏 */
            exitGame() {
                this.$root.ws.wsSend('all_logout', this.$root.user.name, this.$root.user.guid);
            },
            /**点击线路 */
            showLine(){
                this.isShowLine=this.isShowLine==true?false:true;
            },
            /**选择线路 */
            chooseLine(name,id){
                this.isShowLine=false;
                this.$emit("change-line",{
                    id:id,
                    name:name
                });
                console.log(name);
            },
            /**切换线路列表页码 */
            changeLinePage(type){
                //当前切换页码后大于第一页或者小于最大页数
                if(this.currentLinePage+type>=1&&this.currentLinePage+type<=Math.ceil(this.lineArr.length/6)){
                    this.currentLinePage+=type;
                }
            },
            showAgreement(){
                this.$emit("show-agreement");
            },
            showFullScreen(){
                fullScreen();
            }
        },
        watch:{
            lineArr(newVal){
                
            }
        }
    }
</script>