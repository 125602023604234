<style scoped src="../assets/css/login.css"></style>
<style>
    /* 动画特效，放在scoped里无法被执行 */
    .circle-perspective{
        animation: abc 5s infinite linear;
        -webkit-animation:abc 5s infinite linear;
        -moz-animation:abc 5s infinite linear;
        -o-animation:abc 5s infinite linear;
    }
    @keyframes abc{
        from {
            transform: rotateY(15deg);
        }
        to {
            transform: rotateY(360deg);
        }
    }
    @-webkit-keyframes abc{
        from {
            transform: rotateY(15deg);
        }
        to {
            transform: rotateY(360deg);
        }
    }
    @media (max-width: 992px){
        .signboard[data-v-26084dc2] {
            width: 230px;
            height: 230px;
            }
    }
    @media (max-width: 768px){
        .signboard[data-v-26084dc2] {
            width: 200px;
            height: 200px;
            }
        }
    @media (max-width: 576px){
        .signboard[data-v-26084dc2] {
            width: 150px;
            height: 150px;
            }
    }

</style>
<template>
    <div class="login-frame">
        <div class="rail"></div>
        <div class="content">
            <!-- 彩带 -->
            <div class="ticker"></div>
            <!-- 灯笼 -->
            <div v-for="i in 2" :key="i" class="lantern-light" :class="i==1?'left-light':'right-light'">
                <div class="lantern-line lantern-top"></div>
                <div class="circle-perspective">
                    <div class="lantern-circle" v-for="j in 12" :key="j" :style="{transform:'rotateY('+30*j+'deg)'}"></div>
                </div>
                <div class="lantern-foot">
                    <div class="lantern-line lantern-bottom"></div>
                    <div class="lantern-line-pearl"></div>
                    <div class="lantern-line-pearl"></div>
                    <div class="lantern-line-tail">
                        <div class="lantern-line-cord-first"></div>
                        <div class="lantern-line-cord-second"></div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-around align-items-center h-100">
                <!-- 图标 -->
                <div class="signboard">
                    <div class="signboard-second">
                        <div class="signboard-third">
                            <div class="signboard-last">
                                <img class="signboard-img" src="../../public/images/signboard.png">
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- 登录框 -->
                <div class="d-flex flex-column align-items-center">
                    <div class="login-box-title px-3">
                        <span>电话投注</span>
                    </div>
                    <div class="login-box px-3 py-4 rounded" @keyup.enter="login">
                        <div class="login-box-inner px-3 py-4 d-flex flex-column">
                            <div class="d-flex">
                                <div class="d-flex flex-column">
                                    <label for="txtUser" class="login-lbl mb-3">用&nbsp;户&nbsp;名：</label>
                                    <label for="txtPwd" class="login-lbl mb-3">密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码：</label>
                                    <label for="txtCode" class="login-lbl mb-3">验&nbsp;证&nbsp;码：</label>
                                </div>
                                <div class="d-flex flex-column" style="width:8em">
                                    <input type="text" id="txtUser" class="login-txt w-100 mb-3" v-model="name">
                                    <input type="password" id="txtPwd" class="login-txt w-100 mb-3" v-model="pwd">
                                    <div class="d-flex mb-3">
                                        <div class="col-7">
                                            <input type="text" id="txtCode" class="login-txt w-100 flex-grow-1" v-model="code">
                                        </div>
                                        <div class="col-5 d-flex flex-column ps-2">
                                            <canvas id="loginCode" class="login-img w-100 h-0 flex-grow-1" @click="changeCode"></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="login-line d-flex mb-1">
                                <div class="d-flex flex-wrap flex-grow-1 w-0">
                                    <div class="d-flex me-2 mb-2" v-for="(key,i,num) in ws" :key="key">
                                        <input type="radio" name="wsline" :id="i" :value="key" v-model="wsLine" :checked="num==0?true:false" >
                                        <label :for="i">{{i}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="login-btn-box">
                                    <button class="login-btn" type="button" @click="login">登 录</button>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="button" class="btn btn-link" @click="linkToChat">
                                    <i class="bi bi-people me-1"></i>
                                    <span>在线客服</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rail"></div>
    </div>
</template>
<script>
    import {drawCode,getDeviceType,getIP} from '@/assets/js/common.js'
    import {useLayer} from "@/assets/js/useLayer.js"
    export default{
        mounted(){
            this.codeCreate = drawCode(document.getElementById("loginCode"));
        },
        data() {
            return {
                name: "",//用户名
                pwd:"",//密码
                code:"",//验证码
                codeCreate:"",//生成的验证码
                ws:config().telws,
                wsLine:Object.values(config().telws)[0],//选中ws线路
            }
        },
        methods: {
            login() {
                if(this.name==""){
                    useLayer({str:"请输入用户名"});
                }else if(this.pwd==""){
                    useLayer({str:"请输入密码"});
                }else if(this.code==""){
                    useLayer({str:"请输入验证码"});
                }else if(this.codeCreate!=this.code){
                    useLayer({str:"验证码错误"});
                }else if(this.wsLine==""){
                    useLayer({str:"请选择线路"});
                }else{
                    this.$root.addMessage("login",(data) => {
                        switch (data.command) {
                            case "tel_login"://登录
                                if(data.result==-1||data.result==-2){
                                    useLayer({str:"用户名或密码错误"});
                                    return;
                                }else if(data.result==1){
                                    var timer = setInterval(()=>{
                                        if(this.$root.ws==null||this.$root.ws.readyState!=1){
                                            clearInterval(timer);
                                            return;
                                        }
                                        let date = new Date();
                                        console.log(date.getHours()+":"+date.getMinutes()+":"+date.getSeconds())
                                        this.$root.ws.wsSend('all_online', this.name, data.guid);
                                    }, 6000)
                                    this.$root.delMessage("login");
                                    this.$root.user.name=this.name;
                                    this.$root.user.guid=data.guid;
                                    this.$router.push("/index");
                                }
                                break;
                            default:
                                break;
                        }
                    });
                    this.$root.initWebsocket(this.wsLine);//this.$root为获取App.vue中的对象
                    this.$root.ws.onopen=() => {
                        this.$root.ws.wsSend("tel_login",this.name,this.pwd,getDeviceType(),getIP());//发送登录
                    }
                }
            },
            changeCode(){
                this.codeCreate = drawCode(document.getElementById("loginCode"));
            },
            linkToChat(){
                window.open(config().chatUrl);
            }
        },
    }
</script>