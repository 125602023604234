<style scoped src="../assets/css/index.css"></style>
<template>
    <div v-if="$root.ws" class="index-frame clearfix">
        <navbar class="navbar" @change-line="changeLine" :current-line="$root.currentLine" :line-arr="lineArr" @show-agreement="showAgreement"></navbar>
        <video-player class="video-player" video-id="video1" :video-url="videoUrlOne"></video-player>
        <video-player class="video-player" video-id="video2" :video-url="videoUrlTwo"></video-player>
        <table-list class="table-list" :current-line="$root.currentLine"></table-list>
        <div class="agreement" v-show="isShowAgreement">
            <ul>
                <li>
                    博彩是您自己的选择和判断，您应该知道及承担用本服务所带来的全部风险。当您是用这服务器时，
                    您确认您没有判断这服务，网站或者信息有任何攻击，令人反感，不公平或不雅的。某些司法管辖
                    区没有解决在网上博彩的合法性，而另一些专门令到网上博彩是非法的。我们无意使任何网上博彩
                    是非法的司法管辖区内的人使用服务或进入网站。在上诉的任何司法管辖区中，我们的服务，网站
                    和信息的可用性不应被理解为提供，招揽或邀请。遵守任何适用您的服务，网站或信息法律服完全
                    是您自己的责任，我们不会对这作任何声明或保证。
                </li>
                <li>
                    我们将竭力使用合理的技能和关注服务，网站，软件和信息。但不能保证我们会对任何服务，网站，
                    软件和信息做任何声明或保证，在超出法律最大范围的允许上，不论是明示或者默示，法定或其他
                    方面的服务。软件，网站和信息均予排除。
                </li>
                <li>
                    本网站和软件都提供了“似乎”。我们不保证服务，软件，网站或信息是准确，完整，适合，及时，
                    安全，无中断，无缺陷，无错误或不受任何性质的外界干扰或任何不明的缺陷将得到纠正。
                </li>
                <li>
                    我们不保证的服务，软件，网站，信息或服务器是没有电脑病毒，漏洞，间谍软件，广告软件或其
                    他恶意，破坏性或毁坏的代码，程式，数据或任何其他功能任何可能，可能会影响装置和/或包括
                    的数据。您将承担您自己的预防措施（自费）确保使用服务软件的过程，措施和/或装置不摆出病
                    毒，破坏性或损坏或其他形式的干扰的风险。
                </li>
                <li>
                    所有结果以龙源公司所示结果为准，龙源公司拥有一切解释权。
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import Navbar from '@/components/public/Navbar.vue'
    import VideoPlayer from '@/components/public/VideoPlayer.vue'
    import TableList from '@/components/public/TableList.vue'
    export default{
        beforeCreate(){
            if(this.$root.ws==null||this.$root.ws.readyState!=1){
                this.$router.push("/");
            }
        },
        components:{
            Navbar,
            VideoPlayer,
            TableList
        },
        data() {
            return {
                lineArr:[],
                isShowAgreement:false,//是否显示协议条款
                videoUrlOne:"",//第一个视频的地址
                videoUrlTwo:"",//第二个视频的地址
            }
        },
        mounted(){
            if(this.$root.ws){
                this.$root.addMessage("index",(data) => {
                    switch (data.command) {
                        case "all_getline"://获取线路列表
                            this.lineArr=data.list;
                            if(this.$root.currentLine.id==""&&this.lineArr.length>0){
                                this.$root.currentLine={
                                    id:this.lineArr[0].id,
                                    name:this.lineArr[0].name
                                }
                            }
                            if(data.list.length>0){
                                this.$emit("change-line",data.list[0]);
                                this.$root.ws.wsSend('all_getvideo', 0, data.list[0].id, this.$root.user.name, this.$root.user.guid);
                            }
                            break;
                        case "all_logout"://注销用户
                            if(data.result==1){
                                this.$root.delMessage("index","navbar","tableList","videoPlayervideo1","videoPlayervideo2");
                                this.$router.push("/");
                            }
                            break;
                        case "all_getvideo"://获取视频
                            this.videoUrlOne=data.flvline+'/'+data.video[0];
                            this.videoUrlTwo=data.flvline+'/'+data.video[1];
                            break;
                        default:
                            break;
                    }
                })
                this.$root.ws.wsSend('all_getline', this.$root.user.name, this.$root.user.guid);
            }
        },
        methods: {
            changeLine(currentLine) {
                if(currentLine.id!=""){
                    this.$root.currentLine=currentLine;
                }
            },
            showAgreement(){
                this.isShowAgreement?(this.isShowAgreement=false):(this.isShowAgreement=true);
            }
        },
        watch:{
            /**
             * 视频线路被改变就重新获取视频
             */
            '$root.currentLine'(newVal){
                this.$root.ws.wsSend('all_getvideo', 0, newVal.id, this.$root.user.name, this.$root.user.guid);
            }
        }
    }
</script>