<style scoped>
    .pagination-frame{
        color: #fff;
        font-size: .6em;
        text-align: center;
    }
    .pagination-frame>*{
        display: inline-block;
        vertical-align: middle;
        margin: 0 .3%;
    }
    .pagination-frame>div{
        height: 100%;
        cursor: pointer;
    }
    .pagination-frame>div>span{
        vertical-align: middle;
    }
    .pagination-frame img{
        display: inline-block;
        height: 95%;
        vertical-align: middle;
    }
    .pagination-num{
        color: #000;
        background: #fff;
        width: 10%;
        height: 80%;
        text-align: center;
        font-weight: bold;
        font-size: 1.1em;
    }
    .img-next,.img-last{
        transform: rotateZ(180deg);
    }
</style>
<template>
    <div class="pagination-frame">
        <div @click="changePage(0)">
            <img src="../../../public/images/page_both.png" alt="">
            <span>首页</span>
        </div>
        <div @click="changePage(-1)">
            <img src="../../../public/images/page_near.png" alt="">
            <span>上一页</span>
        </div>
        <span class="pagination-num">{{currentPage}}</span>
        <div @click="changePage(1)">
            <span>下一页</span>
            <img class="img-next" src="../../../public/images/page_near.png" alt="">
        </div>
        <div @click="changePage(2)">
            <span>尾页</span>
            <img class="img-last" src="../../../public/images/page_both.png" alt="">
        </div>
        <span>共</span>
        <span class="pagination-num">{{countPage}}</span>
        <span>页</span>
    </div>
</template>
<script>
    export default {
        props: ["currentPage","countPage"],
        methods: {
            changePage(type) {
                this.$emit("change-page",type);
            },
        }
    }
</script>