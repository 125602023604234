<style scoped>
    .table-list-block{
        background: rgba(0,0,0,.8);
    }
    .list-content{
        height: 100%;
    }
    .list-content>div{
        border-radius: 15%;
        width: 8.84%;
        height: 8.675%;
        float: left;
        margin: 1% .55%;
        font-size: .5em;
        text-align: center;
        line-height: 100%;
        padding: .2% 0;
        overflow: hidden;
        color: #0a281e;
        cursor: pointer;
    }
    .list-content>div.bai{
        background: radial-gradient(5em at 25% 0%,#fdfbd4 0%,#fad588 45%,#fcc678);
    }
    .list-content>div.active{
        background: radial-gradient(5em at 25% 0%,#dcf6e2 0%,#35a87d 45%,#078763);
    }
    .list-content>div.long{
        background: radial-gradient(5em at 25% 0%,#fdf8bd 0%,#f6c400 45%,#f07a15);
    }
</style>
<template>
    <div class="table-list-block" v-show="isShow">
        <div class="list-content clearfix">
            <div v-for="item in tableArr" :key="item" @click="changeTable(item.id,item.game)" :class="tableId==item.id?'active':(item.game==1?'bai':'long')">{{item.room+item.table}}</div>
        </div>
    </div>
</template>
<script>
    export default {
        props:["isShow","tableArr","tableId"],
        methods: {
            changeTable(id,game){
                this.$emit("change-game",game);
                this.$root.ws.wsSend("all_entertable",1,id,this.$root.user.name,this.$root.user.guid);
            },
        },
    }
</script>